import React from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo_image from '../../assets/logo.svg'

function NavBar() {
	const activeRouter = window.location.pathname

	return (
		<Navbar expand='lg' className='navContainer'>
			<Container>
				<Link to={'/'} className='custom_link_logo_brand'>
					<Navbar.Brand>
						<img
							src={logo_image}
							width='30'
							height='30'
							className='d-inline-block align-top'
							alt='Ciircle Logo'
						/>{' '}
						<p className='custom_link_logo_brand_text'>Ciircle</p>
					</Navbar.Brand>
				</Link>

				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='me-auto' />
					<Nav className=' my-2 my-lg-0'>
						<NavButtons activeRouter={activeRouter} />
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

const NavButtons = ({ activeRouter }) => {
	const list = [
		// {
		// 	id: 0,
		// 	title: 'Contact',
		// 	route: '/contact',
		// },
		// {
		// 	id: 1,
		// 	title: 'About',
		// 	route: '/about',
		// },
		// {
		// 	id: 2,
		// 	title: 'privacy',
		// 	route: '/privacy',
		// },
		{
			id: 3,
			title: 'download',
			route: '/download',
		},
	]

	return list.map((item) => (
		<Link to={item.route} key={item.id} className='custom_link_nav_link'>
			<Button variant={activeRouter === item.route ? 'dark' : ''} size='sm'>
				{item.title}
			</Button>
		</Link>
	))
}

export default NavBar
