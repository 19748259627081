import React from 'react'
import NavBar from '../components/common/NavBra'

function Contact() {
	return (
		<div className='container'>
			<NavBar />
		</div>
	)
}

export default Contact
