import { Container, Row, Col, Button } from 'react-bootstrap'
import React from 'react'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import '../../styles/footer.css'
import logo_image from '../../assets/logo.svg'
import { Link } from 'react-router-dom'
import { main_color } from '../../libs/configs'

const Footer = () => {
	return (
		<Container className='footerContainer'>
			<Row>
				<Col sm={6}>
					<div className='footer_left_content footer_content_section'>
						<img src={logo_image} className='footerLogo' alt='Ciircle Logo' />
						<p className='footerText'>© 2022 Ciircle, all rights reserved</p>
					</div>
				</Col>
				<Col sm={6}>
					<div className='footer_right_content footer_content_section'>
						<FooterSocialMedia />
						<FooterList />
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default Footer

const FooterSocialMedia = () => {
	const list = [
		{
			id: 0,
			icon: <BsFacebook color={main_color} size={20} />,
			href: 'https://facebook/ciircle',
		},
		{
			id: 3,
			icon: <BsTwitter color={main_color} size={20} />,
			href: 'https://twitter/ciircle',
		},
		{
			id: 2,
			icon: <BsInstagram color={main_color} size={20} />,
			href: 'https://instagram/ciircle',
		},
	]

	return (
		<div className='socialMediaButtonsContainer'>
			{list.map((item) => (
				<Button
					key={item.id}
					className='socialMediaButton'
					href={item.href}
					target='_blank'
				>
					{item.icon}
				</Button>
			))}
		</div>
	)
}

const FooterList = () => {
	const list = [
		{
			id: 0,
			title: 'Contact',
			href: '/contact',
		},
		{
			id: 3,
			title: 'About Us',
			href: '/about',
		},
		{
			id: 2,
			title: 'Privacy',
			href: '/privacy',
		},
	]

	return (
		<div>
			{list.map((item) => (
				<Link key={item.id} className='footerListText' to={item.href}>
					{item.title}
				</Link>
			))}
		</div>
	)
}
