import React from 'react'
import '../styles/notfound.css'
import { Container, Row, Col } from 'react-bootstrap'
import NavBar from '../components/common/NavBra'
import Footer from '../components/common/Footer'

const PageNotFound = () => {
	return (
		<div className='home_html'>
			<div className='notfoundContainer'>
				<NavBar />
				<Container className='contentContainer'>
					<Row>
						<Col sm={6}>
							<div className='notfoundImageContainer'>
								<img
									className='notffoundImg'
									src='https://i.imgur.com/A040Lxr.png'
									alt='Ciircle, Page Not Fond'
								/>
							</div>
						</Col>
						<Col sm={6}>
							<div className='info'>
								<h2 className='notfoundText'>This Page is Lost in Space</h2>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</div>
	)
}

export default PageNotFound
