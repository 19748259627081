import React, { useState } from 'react'
import NavBar from './NavBra'
import { BsEnvelope } from 'react-icons/bs'
import '../../styles/header.css'
import salyImage from '../../assets/saly.svg'
import ellipseImage from '../../assets/ellipse.svg'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import appstore from '../../assets/appstore.svg'
import playstore from '../../assets/playstore.svg'
import { appstoreurl, playstoreurl, gSheetURL } from '../../libs/configs'
import mock_image from '../../assets/mock.svg'
import swal from 'sweetalert'

function Header({
	title,
	subtitle,
	withInput,
	isSeventyPercentHeight,
	isDownload,
	widthImages,
}) {
	return (
		<div className='home_html'>
			<header
				className={
					isSeventyPercentHeight
						? 'home_container seventy_vh'
						: 'home_container'
				}
			>
				<NavBar />
				{widthImages && (
					<>
						<FirstImage />
						<SecondImage />
					</>
				)}
				{isDownload ? (
					<DownloadHeader title={title} subtitle={subtitle} />
				) : (
					<MainHeader
						title={title}
						subtitle={subtitle}
						withInput={withInput}
						isSeventyPercentHeight={isSeventyPercentHeight}
					/>
				)}
			</header>
		</div>
	)
}

export default Header

const MainHeader = ({ title, subtitle, withInput, isSeventyPercentHeight }) => {
	return (
		<Container>
			<TitleSubtitle
				title={title}
				subtitle={subtitle}
				withInput={withInput}
				isSeventyPercentHeight={isSeventyPercentHeight}
			/>
			{withInput && <SubscribeForm />}
		</Container>
	)
}

const DownloadHeader = ({ title, subtitle }) => {
	return (
		<Container>
			<Row>
				<Col sm={6}>
					<div className='mockContainer'>
						<img
							alt='Ciircle App Store'
							src={mock_image}
							className='mockImage'
						/>
					</div>
				</Col>
				<Col sm={6}>
					<DownloadTitleSubtitle title={title} subtitle={subtitle} />
				</Col>
			</Row>
		</Container>
	)
}

const DownloadTitleSubtitle = ({ title, subtitle }) => {
	return (
		<div className={'downloadContentContainer'}>
			<h1 className='title textAlignStart'>{title}</h1>
			<h5 className='subtitle textAlignStart'>{subtitle}</h5>
			<Row className='downloadButtons'>
				<Col sm={6} className='downloadButtonCol'>
					<a
						target={'_blank'}
						rel='noreferrer'
						href={appstoreurl}
						className='downloadImgAnchor '
					>
						<img
							alt='Ciircle App Store'
							src={appstore}
							className='downloadImg'
						/>
					</a>
				</Col>
				<Col sm={6} className='downloadButtonCol'>
					<a
						target={'_blank'}
						rel='noreferrer'
						href={playstoreurl}
						className='downloadImgAnchor'
					>
						<img
							alt='Ciircle Play Store'
							src={playstore}
							className='downloadImg'
						/>
					</a>
				</Col>
			</Row>
		</div>
	)
}

const FirstImage = () => {
	return <img src={salyImage} className='first_image' alt='Ciircle' />
}

const SecondImage = () => {
	return <img src={ellipseImage} className='second_image' alt='Ciircle' />
}

const TitleSubtitle = ({
	title,
	subtitle,
	withInput,
	isSeventyPercentHeight,
}) => {
	return (
		<div
			className={
				withInput || isSeventyPercentHeight
					? 'home_main_content'
					: 'home_main_content_fill_one'
			}
		>
			<h1 className='title'>{title}</h1>
			<h5 className='subtitle'>{subtitle}</h5>
		</div>
	)
}

const SubscribeForm = () => {
	const [email, setEmail] = useState('')
	const [isEmailEmpty, setEmailEmpty] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = async (_email) => {
		const isValid = validateEmail(_email)
		if (isValid) {
			setLoading(true)
			const data = { email: _email }
			const params = {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}
			try {
				const responde = await fetch(gSheetURL, params)
				await responde.json()
				swal({
					title: 'Good job!',
					text: "You're now at Ciircle waitlist!",
					icon: 'success',
					button: 'Aww yiss!',
				})
				setEmail('')
				setLoading(false)
			} catch (error) {
				swal({
					title: 'Oops!',
					text: 'Something went wrong, please try again!.',
					icon: 'error',
					button: 'Try again!',
				})
				setLoading(false)
			}
		}
	}

	const validateEmail = (_email) => {
		const isValid = String(_email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
		if (isValid) {
			setEmailEmpty(false)
		} else {
			setEmailEmpty(true)
		}
		return isValid
	}

	const onChange = (event) => {
		const value = event.target.value
		setEmail(value)
		validateEmail(value)
	}

	return (
		<div className='form_container'>
			<div
				className={
					isEmailEmpty
						? 'invalid_form_content_container'
						: 'form_content_container'
				}
			>
				<div className='email_container'>
					<BsEnvelope className='emailIcon' />
					<input
						placeholder='Email Address'
						className='email_input'
						onSubmit={(event) => {
							event.preventDefault()
							onSubmit(email)
						}}
						required
						type={'email'}
						value={email}
						onChange={(event) => onChange(event)}
					/>
				</div>
				<div className='submit_button_container'>
					<button onClick={() => onSubmit(email)} className='submit_button'>
						{isLoading ? (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
							/>
						) : (
							'Request Invite'
						)}
					</button>
				</div>
			</div>
		</div>
	)
}
