import React from 'react'
import '../styles/home.css'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import ContactSecion from '../components/common/ContactSection'

const headerTitle = 'Try Ciircle'
const headerSubTitle = 'Get the app.'

function Download() {
	return (
		<div className='home_html'>
			<Header title={headerTitle} subtitle={headerSubTitle} isDownload={true} />
			<ContactSecion />
			<Footer />
		</div>
	)
}

export default Download
