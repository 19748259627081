import React from 'react'
import '../styles/about.css'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import { Button, Container } from 'react-bootstrap'

const headerTitle = 'Welcome To Ciircle.'
const headerSubTitle =
	'Ciircle is a time-based social network. We simplify chaotic high school schedules and connect students with the people and information that matter most.'
const about_title = 'Our Story'
const paragraph_one =
	'It all started in 2020. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
const paragraph_two =
	'It all started in 2020. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
const joinusTitle = 'Join Us'
const joinusSubTitle = "We're Hiring !!!"
const joinus_1st_prg =
	'We work with people who are the best at what they do, and who care about making others the best at what they do too. Launching great products is just the start of our jobs. We focus as much on building culture as we do on building software'
const joinus_2nd_prg = 'Contact us today to get started!'

const About = () => {
	return (
		<div className='home_html'>
			<Header
				title={headerTitle}
				subtitle={headerSubTitle}
				isSeventyPercentHeight={true}
				widthImages={true}
			/>
			<Container>
				<AboutSection />
				<JoinusSection />
				<Footer />
			</Container>
		</div>
	)
}

export default About

const AboutSection = () => {
	return (
		<div className='aboutSectionMainContainer'>
			<div className='aboutSectionContainer'>
				<h1 className='aboutSectionTitle'>{about_title}</h1>
				<br />
				<p className='aboutSectionParagraph'>{paragraph_one}</p>
				<br />
				<p className='aboutSectionParagraph'>{paragraph_two}</p>
			</div>
		</div>
	)
}

const JoinusSection = () => {
	return (
		<div className='aboutSectionMainContainer'>
			<div className='moduleBorderWrap '>
				<div className='aboutSectionContainer '>
					<h1 className='aboutSectionTitle'>{joinusTitle}</h1>
					<br />
					<h5 className='joinusSubtitle'>{joinusSubTitle}</h5>
					<br />
					<p className='aboutSectionParagraph'>{joinus_1st_prg}</p>
					<br />
					<p className='aboutSectionParagraph'>{joinus_2nd_prg}</p>
					<br />
					<Button className='contactButton'>GET IN TOUCH</Button>
				</div>
			</div>
		</div>
	)
}
