import '../../styles/contactSection.css'
import { Button, Container } from 'react-bootstrap'
import contact_image_1 from '../../assets/contact_f1.svg'
import box_image from '../../assets/box.svg'

const ContactSecion = () => {
	return (
		<Container>
			<div className='contactMainContainer'>
				<div className='contactContainer'>
					<div className='contactTextContainer'>
						<h3 className='contactTitle'>Want to learn more?</h3>
					</div>
					<div className='contactButtonContainer'>
						<Button className='contactButton'>GET IN TOUCH</Button>
					</div>
					<ContactImages />
				</div>
			</div>
		</Container>
	)
}

export default ContactSecion

const ContactImages = () => {
	return (
		<div className='mock_images_container contact_images'>
			<img className='image_1' src={contact_image_1} alt='Ciircle' />
			<img className='image_2' src={box_image} alt='Ciircle' />
		</div>
	)
}
