import './App.css'
import Router from './router'
import { BrowserRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { Circles } from 'react-loader-spinner'

function App() {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 500)
	}, [])

	if (loading) {
		return (
			<div className='loaderContainer'>
				<Circles color={'#000'} height={100} width={100} />
			</div>
		)
	} else {
		return (
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		)
	}
}

export default App
