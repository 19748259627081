import React from 'react'
import Home from './screens/Home'
import About from './screens/About'
import PageNotFound from './screens/PageNotFound'
import { Route, Routes } from 'react-router-dom'
import Download from './screens/Download.js'
import Privacy from './screens/Privacy'
import Contact from './screens/Contact'

function Routers() {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/contact' element={<Contact />} />
			<Route path='/about' element={<About />} />
			<Route path='/privacy' element={<Privacy />} />
			<Route path='/download' element={<Download />} />
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	)
}

export default Routers
