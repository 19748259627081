import React from 'react'
import '../styles/home.css'
import Header from '../components/common/Header'
import salyGreenImage from '../assets/saly_green.svg'
import phoneImage from '../assets/phone.svg'
import safeImage from '../assets/safe.svg'
import cameraImage from '../assets/camera.svg'
import box_image from '../assets/box.svg'
import circle_image from '../assets/circle.svg'
import circle_green_image from '../assets/circle_green.svg'
import mock_image from '../assets/mock.svg'
import big_black_box from '../assets/big_black_box.svg'
import triangleGreenImage from '../assets/triangle_green.svg'
import { Card, CardGroup, Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/common/Footer'
import ContactSecion from '../components/common/ContactSection'

const headerTitle = 'THE BEST WAY TO MAKE PLANS WITH FRIENDS'
const headerSubTitle =
	'Hangouts, Parties, Clubs. Ciircle has all you need for a succesful event.'

function Home() {
	return (
		<div className='home_html'>
			<Header
				title={headerTitle}
				subtitle={headerSubTitle}
				withInput={true}
				widthImages={true}
			/>
			<InfoItems />
			<MockOne />
			<MockTwo />
			<MockThree />
			<ContactSecion />
			<Footer />
		</div>
	)
}

const MockOne = () => {
	return (
		<div className='mock_container mock_one'>
			<Container>
				<Row>
					<Col sm={6}>
						<div className='mock_content_col mock_image'>
							<img className='mock_img' alt='Ciircle App' src={mock_image} />
						</div>
					</Col>
					<Col sm={6}>
						<div className='mock_content_col mock_text'>
							<h1 className='mock_title'>Chat. Built around your day.</h1>
							<p className='mock_subtitle'>
								Talk with your friends, classes, and grade. Want more privacy?
								Take it to a DM.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
			<MockOneImages />
		</div>
	)
}

const MockOneImages = () => {
	return (
		<div className='mock_images_container'>
			<img className='first_mock' src={salyGreenImage} alt='Ciircle' />
			<img className='big_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_green_box' src={box_image} alt='Ciircle' />
			<img className='small_green_circle' src={circle_image} alt='Ciircle' />
		</div>
	)
}

const MockTwo = () => {
	return (
		<div className='mock_container mock_two'>
			<Container>
				<Row>
					<Col sm={6}>
						<div className='mock_content_col mock_text'>
							<h1 className='mock_title'>
								Friends, Chats And Social Profiles.
							</h1>
							<p className='mock_subtitle'>
								Everything you need - all in one place.
							</p>
						</div>
					</Col>
					<Col sm={6}>
						<div className='mock_content_col mock_image'>
							<img className='mock_img' alt='Ciircle App' src={mock_image} />
						</div>
					</Col>
				</Row>
			</Container>
			<MockTwoImages />
		</div>
	)
}

const MockTwoImages = () => {
	return (
		<div className='mock_images_container'>
			<img
				className='big_green_circle'
				src={circle_green_image}
				alt='Ciircle'
			/>
			<img className='big_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_green_box' src={box_image} alt='Ciircle' />
			<img className='small_green_circle' src={circle_image} alt='Ciircle' />
		</div>
	)
}

const MockThree = () => {
	return (
		<div className='mock_container'>
			<Container>
				<Row>
					<Col sm={6}>
						<div className='mock_content_col mock_image'>
							<img className='mock_img' alt='Ciircle App' src={mock_image} />
						</div>
					</Col>
					<Col sm={6}>
						<div className='mock_content_col mock_text'>
							<h1 className='mock_title'>
								It’s time to ditch the group chats.
							</h1>
							<p className='mock_subtitle'>
								And say goodbye to the Notes app. Manage your plans in Ciircle.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
			<MockThreeImages />
		</div>
	)
}

const MockThreeImages = () => {
	return (
		<div className='mock_images_container mock_three'>
			<img className='first_mock' src={triangleGreenImage} alt='Ciircle' />
			<img className='big_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_black_box' src={big_black_box} alt='Ciircle' />
			<img className='small_green_box' src={box_image} alt='Ciircle' />
			<img className='small_green_circle' src={circle_image} alt='Ciircle' />
		</div>
	)
}

const InfoItems = () => {
	return (
		<div className='info_items_main_container'>
			<Container>
				<div className='info_items_container'>
					<BoxFirstImages />
					<BoxSecondImages />
					<TitleSubtitleBoxes />
					<Cards />
				</div>
			</Container>
		</div>
	)
}

const TitleSubtitleBoxes = () => {
	return (
		<div className='info_text_container'>
			<h1 className='info_items_title'>Ciircle makes your life easy </h1>
			<p className='info_items_subtitle'>
				We took your plans and added your friends. Ciircle makes every moment
				social by connecting your friends and events. In real-time.
			</p>
		</div>
	)
}

const Cards = () => {
	const list = [
		{
			id: 0,
			title: 'Easy Hosting',
			message: 'Chat with members and manage your attendance.',
			image: phoneImage,
		},
		{
			id: 1,
			title: 'Host Safe Events',
			message:
				'Easy-to-use features for indicating whether events will be outdoors, have mask or vaccination requirements, & more.',
			image: safeImage,
		},
		{
			id: 2,
			title: 'Virtual & In-Person',
			message:
				'Create immersive virtual and in-person event experiences for your audience, no matter where they are.',
			image: cameraImage,
		},
	]
	return (
		<CardGroup>
			{list.map((item) => (
				<Card className='card_container' key={item.id}>
					<div className='card_item_container'>
						<Card.Img className='card_image' variant='top' src={item.image} />
						<Card.Body className='card_body'>
							<Card.Title className='card_title'>{item.title}</Card.Title>
							<Card.Text className='card_subtitle'>{item.message}</Card.Text>
						</Card.Body>
					</div>
				</Card>
			))}
		</CardGroup>
	)
}

const BoxFirstImages = () => {
	return (
		<div className='first_box'>
			<img src={box_image} className='image_1' alt='Ciircle' />
			<img src={box_image} className='image_2' alt='Ciircle' />
			<img src={circle_image} className='image_3' alt='Ciircle' />
		</div>
	)
}

const BoxSecondImages = () => {
	return (
		<div className='second_box'>
			<img src={circle_image} className='image_1' alt='Ciircle' />
			<img src={box_image} className='image_2' alt='Ciircle' />
			<img src={box_image} className='image_3' alt='Ciircle' />
		</div>
	)
}

export default Home
